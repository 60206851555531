import { supabase, d1Client, firestore } from './dbClients';
import { collection, addDoc, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';

export const dbOperations = {
  supabase: {
    async create(data) {
      const start = performance.now();
      const { error } = await supabase.from('speed_test').insert(data);
      const end = performance.now();
      if (error) throw error;
      return end - start;
    },
    async read(id) {
      const start = performance.now();
      const { error } = await supabase.from('speed_test').select().eq('id', id);
      const end = performance.now();
      if (error) throw error;
      return end - start;
    },
    async update(id, data) {
      const start = performance.now();
      const { error } = await supabase.from('speed_test').update(data).eq('id', id);
      const end = performance.now();
      if (error) throw error;
      return end - start;
    },
    async delete(id) {
      const start = performance.now();
      const { error } = await supabase.from('speed_test').delete().eq('id', id);
      const end = performance.now();
      if (error) throw error;
      return end - start;
    },
  },
  d1: {
    async create(data) {
      const { duration } = await d1Client.query('create', null, data);
      return duration;
    },
    async read(id) {
      const { duration } = await d1Client.query('read', id);
      return duration;
    },
    async update(id, data) {
      const { duration } = await d1Client.query('update', id, data);
      return duration;
    },
    async delete(id) {
      const { duration } = await d1Client.query('delete', id);
      return duration;
    },
  },
  firestore: {
    async create(data) {
      try {
        const start = performance.now();
        const docRef = await addDoc(collection(firestore, 'speed_test'), data);
        const end = performance.now();
        return { duration: end - start, id: docRef.id };
      } catch (error) {
        console.error('Firestore create error:', error);
        throw error;
      }
    },
    async read(id) {
      try {
        const start = performance.now();
        const docRef = doc(firestore, 'speed_test', id);
        const docSnap = await getDoc(docRef);
        const end = performance.now();
        if (!docSnap.exists()) {
          console.error('Document not found');
          throw new Error('Document not found');
        }
        return end - start;
      } catch (error) {
        console.error('Firestore read error:', error);
        throw error;
      }
    },
    async update(id, data) {
      try {
        const start = performance.now();
        const docRef = doc(firestore, 'speed_test', id);
        await updateDoc(docRef, data);
        const end = performance.now();
        return end - start;
      } catch (error) {
        console.error('Firestore update error:', error);
        throw error;
      }
    },
    async delete(id) {
      try {
        const start = performance.now();
        const docRef = doc(firestore, 'speed_test', id);
        await deleteDoc(docRef);
        const end = performance.now();
        return end - start;
      } catch (error) {
        console.error('Firestore delete error:', error);
        throw error;
      }
    },
  },
};