import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, CardHeader, CardContent } from './components/ui/card';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import { dbOperations } from './lib/dbOperations';

const App = () => {
  const [data, setData] = useState([
    { name: 'Read', supabase: { avg: 50, p90: 55, p95: 58 }, d1: { avg: 45, p90: 50, p95: 53 }, firestore: { avg: 48, p90: 53, p95: 56 } },
    { name: 'Write', supabase: { avg: 70, p90: 75, p95: 78 }, d1: { avg: 65, p90: 70, p95: 73 }, firestore: { avg: 68, p90: 73, p95: 76 } },
    { name: 'Update', supabase: { avg: 60, p90: 65, p95: 68 }, d1: { avg: 55, p90: 60, p95: 63 }, firestore: { avg: 58, p90: 63, p95: 66 } },
    { name: 'Delete', supabase: { avg: 55, p90: 60, p95: 63 }, d1: { avg: 50, p90: 55, p95: 58 }, firestore: { avg: 53, p90: 58, p95: 61 } },
  ]);
  const [testRuns, setTestRuns] = useState(10);
  const [loading, setLoading] = useState(false);

  const runSpeedTest = async () => {
    setLoading(true);
    const results = {
      supabase: { create: [], read: [], update: [], delete: [] },
      d1: { create: [], read: [], update: [], delete: [] },
      firestore: { create: [], read: [], update: [], delete: [] }
    };
  
    try {
      for (let i = 0; i < testRuns; i++) {
        const testData = { name: `Test ${i}`, value: Math.random() };
        
        // Supabase operations
        const supabaseCreateTime = await dbOperations.supabase.create(testData);
        results.supabase.create.push(supabaseCreateTime);
        
        const supabaseReadTime = await dbOperations.supabase.read(1); // Assuming ID 1 exists
        results.supabase.read.push(supabaseReadTime);
        
        const supabaseUpdateTime = await dbOperations.supabase.update(1, { ...testData, name: `Updated ${i}` });
        results.supabase.update.push(supabaseUpdateTime);
        
        const supabaseDeleteTime = await dbOperations.supabase.delete(1);
        results.supabase.delete.push(supabaseDeleteTime);
    
        // D1 operations
        const d1CreateTime = await dbOperations.d1.create(testData);
        results.d1.create.push(d1CreateTime);
        
        const d1ReadTime = await dbOperations.d1.read(1); // Assuming ID 1 exists
        results.d1.read.push(d1ReadTime);
        
        const d1UpdateTime = await dbOperations.d1.update(1, { ...testData, name: `Updated ${i}` });
        results.d1.update.push(d1UpdateTime);
        
        const d1DeleteTime = await dbOperations.d1.delete(1);
        results.d1.delete.push(d1DeleteTime);

        // Firestore operations
        try {
          const firestoreCreateTime = await dbOperations.firestore.create(testData);
          results.firestore.create.push(firestoreCreateTime.duration);
          const firestoreDocId = firestoreCreateTime.id;

          const firestoreReadTime = await dbOperations.firestore.read(firestoreDocId);
          results.firestore.read.push(firestoreReadTime);

          const firestoreUpdateTime = await dbOperations.firestore.update(firestoreDocId, { ...testData, name: `Updated ${i}` });
          results.firestore.update.push(firestoreUpdateTime);

          const firestoreDeleteTime = await dbOperations.firestore.delete(firestoreDocId);
          results.firestore.delete.push(firestoreDeleteTime);
        } catch (error) {
          console.error('Firestore operation error:', error);
        }
      }
    
      // Calculate averages and percentiles
      const calculateStats = (times) => ({
        avg: times.reduce((a, b) => a + b, 0) / times.length,
        p90: times.sort((a, b) => a - b)[Math.floor(times.length * 0.9)],
        p95: times.sort((a, b) => a - b)[Math.floor(times.length * 0.95)],
      });
    
      setData([
        { name: 'Create', supabase: calculateStats(results.supabase.create), d1: calculateStats(results.d1.create), firestore: calculateStats(results.firestore.create) },
        { name: 'Read', supabase: calculateStats(results.supabase.read), d1: calculateStats(results.d1.read), firestore: calculateStats(results.firestore.read) },
        { name: 'Update', supabase: calculateStats(results.supabase.update), d1: calculateStats(results.d1.update), firestore: calculateStats(results.firestore.update) },
        { name: 'Delete', supabase: calculateStats(results.supabase.delete), d1: calculateStats(results.d1.delete), firestore: calculateStats(results.firestore.delete) },
      ]);
    } catch (error) {
      console.error('Speed test error:', error);
      // Optionally, you can set an error state here and display it to the user
    } finally {
      setLoading(false);
    }
  };

  const chartData = data.flatMap(item => [
    { name: `${item.name} (Avg)`, Supabase: item.supabase.avg, 'Cloudflare D1': item.d1.avg, 'Firestore': item.firestore.avg },
    { name: `${item.name} (P90)`, Supabase: item.supabase.p90, 'Cloudflare D1': item.d1.p90, 'Firestore': item.firestore.p90 },
    { name: `${item.name} (P95)`, Supabase: item.supabase.p95, 'Cloudflare D1': item.d1.p95, 'Firestore': item.firestore.p95 },
  ]);

  const getWinnerStyle = (values) => {
    const minValue = Math.min(...values);
    return (value) => value === minValue ? { fontWeight: 'bold', color: 'green' } : {};
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Database Speed Comparison: Supabase vs Cloudflare D1 vs Firestore</h1>
      <div className="mb-4 flex items-center space-x-4">
        <Input
          type="number"
          value={testRuns}
          onChange={(e) => setTestRuns(Number(e.target.value))}
          placeholder="Number of test runs"
          className="w-48"
        />
        <Button onClick={runSpeedTest} disabled={loading}>
          {loading ? 'Running Test...' : 'Run Speed Test'}
        </Button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card>
          <CardHeader>
            <h2 className="text-xl font-semibold">Speed Comparison Chart (ms)</h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Supabase" fill="#3ECF8E" />
                <Bar dataKey="Cloudflare D1" fill="#F6821F" />
                <Bar dataKey="Firestore" fill="#FFCA28" />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <h2 className="text-xl font-semibold">Detailed Comparison</h2>
          </CardHeader>
          <CardContent>
            <div className="overflow-x-auto">
              <table className="min-w-full border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border px-4 py-2">Operation</th>
                    <th className="border px-4 py-2">Metric</th>
                    <th className="border px-4 py-2">Supabase (ms)</th>
                    <th className="border px-4 py-2">Cloudflare D1 (ms)</th>
                    <th className="border px-4 py-2">Firestore (ms)</th>
                  </tr>
                </thead>
                <tbody>
                  {data.flatMap((item) => {
                    const avgWinnerStyle = getWinnerStyle([item.supabase.avg, item.d1.avg, item.firestore.avg]);
                    const p90WinnerStyle = getWinnerStyle([item.supabase.p90, item.d1.p90, item.firestore.p90]);
                    const p95WinnerStyle = getWinnerStyle([item.supabase.p95, item.d1.p95, item.firestore.p95]);

                    return [
                      <tr key={`${item.name}-avg`} className="bg-white">
                        <td rowSpan="3" className="border px-4 py-2">{item.name}</td>
                        <td className="border px-4 py-2">Average</td>
                        <td className="border px-4 py-2" style={avgWinnerStyle(item.supabase.avg)}>{item.supabase.avg.toFixed(1)}</td>
                        <td className="border px-4 py-2" style={avgWinnerStyle(item.d1.avg)}>{item.d1.avg.toFixed(1)}</td>
                        <td className="border px-4 py-2" style={avgWinnerStyle(item.firestore.avg)}>{item.firestore.avg.toFixed(1)}</td>
                      </tr>,
                      <tr key={`${item.name}-p90`} className="bg-gray-50">
                        <td className="border px-4 py-2">90th Percentile</td>
                        <td className="border px-4 py-2" style={p90WinnerStyle(item.supabase.p90)}>{item.supabase.p90.toFixed(1)}</td>
                        <td className="border px-4 py-2" style={p90WinnerStyle(item.d1.p90)}>{item.d1.p90.toFixed(1)}</td>
                        <td className="border px-4 py-2" style={p90WinnerStyle(item.firestore.p90)}>{item.firestore.p90.toFixed(1)}</td>
                      </tr>,
                      <tr key={`${item.name}-p95`} className="bg-white">
                        <td className="border px-4 py-2">95th Percentile</td>
                        <td className="border px-4 py-2" style={p95WinnerStyle(item.supabase.p95)}>{item.supabase.p95.toFixed(1)}</td>
                        <td className="border px-4 py-2" style={p95WinnerStyle(item.d1.p95)}>{item.d1.p95.toFixed(1)}</td>
                        <td className="border px-4 py-2" style={p95WinnerStyle(item.firestore.p95)}>{item.firestore.p95.toFixed(1)}</td>
                      </tr>
                    ];
                  })}
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default App;